// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_qV d_bC";
export var storyRowWrapper = "q_hv d_hv d_bJ";
export var storyLeftWrapper = "q_qW d_by d_bN";
export var storyWrapperFull = "q_qX d_cC";
export var storyWrapperFullLeft = "q_nL d_cC d_by d_bN d_bC";
export var contentWrapper = "q_px d_hw";
export var storyLeftWrapperCenter = "q_qY d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "q_qZ d_hC";
export var storyHeader = "q_q0 d_hB d_v d_cr";
export var storyHeaderCenter = "q_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "q_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "q_q1 d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "q_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "q_q2 d_ff d_Y";
export var imageWrapperFull = "q_q3 d_v d_G d_bd d_Y";